import React, { useState } from 'react';

function Contact(){
      return (
        <div>
        <div id="tl-site-top" className="tl-site-top">
          <header id="tl-header" className="tl-header">
            <div className="tl-logo-area">
              <div className="container">
                <div className="row align-items-lg-center">
                  <div className="col-lg-2">
                    <div className="tl-logo">
                      <a href="index-2.html"><img src="assets/images/logos/logo-gg.jpg" style={{width: '100%'}} className="mg-fluid" /></a>
                    </div>
                  </div> 
                  <div className="col-lg-8">
                    <nav className="navbar navbar-expand-lg">
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                          <i className="icon icon-menu" />
                        </span>
                      </button>
                      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav ">
                          <li className="nav-item dropdown ">
                            <a className="nav-link" href="/">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="about">
                              About us
                            </a>
                          </li>
                          <li className="nav-item ">
                            <a className="nav-link" href="contact">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div> 
                    </nav> 
                  </div> 
                </div> 
              </div>
            </div>
          </header>
        </div>
        <div id="banner-area" className="banner-area" style={{backgroundImage: 'url(assets/images/banner/banner-img1.jpg)'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="banner-title">Contact Us</h2>
            </div>
          </div> 
        </div> 
      </div>
        <section id="main-container" className="main-container">
        <div  className="tl-contact-us">
          <div className="container">
            <div className="row justify-content-lg-center">
              <div className="col-lg-3 col-md-4">
                <div className="contact-us-wrapper">
                  <h6 className="contact-title">Our Contact Number</h6>
                  <ul className="unstyled">
                    <li />
                    <li> +(416) 206 2322</li>
                    <li />
                  </ul> 
                </div>
              </div> 
              <div className="col-lg-4 col-md-4">
                <div className="contact-us-wrapper">
                  <h6 className="contact-title">Our address</h6>
                  <p>Unit 706, 1280 finch avenue west
                    <br />  North York, Ontario
                    <br />  M3J 3K6
                    <br />Canada Canada</p>
                </div>
              </div> 
              <div className="col-lg-3 col-md-4">
                <div className="contact-us-wrapper">
                  <h6 className="contact-title">Further Enquiries</h6>
                  <p className="contact-mail">Reach us via email on: <span>info@almakconsulting.com</span></p>
                </div> 
              </div> 
            </div> 
          </div>
        </div> 
        <div className="tl-conact-form ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="column-title">Leave a message</h2>
                <p>Fill the information below, Our correspondent woudl reach out to you as soon as possible</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form className="contact-form" id="contact-form" action="https://getform.io/f/1ff710f4-42b1-4c3b-b822-4a1760bf1d64" method="POST">
                  <div className="error-container" />
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input className="form-control form-name" id="name" name="name" placeholder="Your Name" type="text" required />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input className="form-control form-email" id="email" name="email" placeholder="Your Email" type="email" required />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input className="form-control form-subject" id="subject" name="subject" placeholder="Phone" type="text"/>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea className="form-control form-message" id="message" placeholder="Your message" rows={8} defaultValue={""} />
                      </div>
                    </div>
                  </div>
                  <div className="text-left">
                    <button className="btn btn-primary" type="submit">Send Now</button>
                  </div>
                </form>
              </div> 
            </div>
          </div>
        </div>
      </section>
        <footer id="tl-footer" className="tl-footer">
          <div id="tl-footer-info" className="tl-footer-info">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <span>At Almak Consulting we pay attention to your needs, and work
                    with you to achieve them.</span>
                  <br />
                  <br />
                  <h3 className="footer-title">Get in Touch!<br />
                    <span>We are here to help</span>
                  </h3> 
                </div> 
                <div className="col-lg-4">
                  <div className="footer-contact-info color-yellow">
                    <i className="icon icon-location-pin" />
                    <h3>We are available</h3>
                    <p>8:00 AM - 4:00 PM</p>
                  </div>
                </div> 
                <div className="col-lg-4">
                  <div className="footer-contact-info color-green">
                    <i className="icon icon-phone" />
                    <h3>Contact Us Today</h3>
                    <p>  +(416) 206 2322</p>
                  </div> 
                </div> 
              </div>
              <br />
              <div className="row">
                <div className="col-lg-3">
                  <p className="copyright-text">© 2021 Almak Consulting.</p>
                </div> 
                <div className="col-lg-3 ml-lg-auto text-lg-right">
                </div> 
              </div> 
              <br />
            </div>
          </div>
          <div className="back-to-top" id="back-to-top" data-offset-top={10}>
            <button className="btn btn-primary" title="Back to Top">
              <i className="fa fa-angle-double-up" />
            </button>
          </div>
        </footer>
      </div>
          
      );
    
  };

export default Contact;
