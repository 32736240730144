import React, { useState } from 'react';

function Home(){
      return (
        <div>
        <div id="tl-site-top" className="tl-site-top">
          <header id="tl-header" className="tl-header">
            <div className="tl-logo-area">
              <div className="container">
                <div className="row align-items-lg-center">
                  <div className="col-lg-2">
                    <div className="tl-logo">
                      <a href="index-2.html"><img src="assets/images/logos/logo-gg.jpg" style={{width: '100%'}} className="mg-fluid" /></a>
                    </div>
                  </div> 
                  <div className="col-lg-8">
                    <nav className="navbar navbar-expand-lg">
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                          <i className="icon icon-menu" />
                        </span>
                      </button>
                      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav ">
                          <li className="nav-item dropdown ">
                            <a className="nav-link" href="/" data-toggle="dropdown">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="about">
                              About us
                            </a>
                          </li>
                          <li className="nav-item ">
                            <a className="nav-link" href="contact">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div> 
                    </nav> 
                  </div> 
                </div> 
              </div>
            </div>
          </header>
        </div>
        <div  className="tl-slider">
          <div className="tl-slider-carousel owl-carousel">
            <div className="tl-slider-wrapper" style={{backgroundColor: '#000038'}}>
              <div className="slider-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="slider-content">
                        <h1 className="slider-title" style={{color: '#febc0a'}}>Are your kids looking to study abroad?</h1>
                        <p className="slider-text" style={{color: '#febc0a'}}>
                          We render various services to ensure your kids fulfil their <br />dream of studying abroad
                        </p> 
                        <div className="slider-btn">
                          <a href="contact" className="btn btn-primary btn-lg">Contact Us today</a>
                        </div> 
                      </div>
                    </div> 
                    <div className="col-lg-7">
                      <div className="slider-content">
                        <div className="slider-img">
                          <span><img src="assets/images/dtp.jpg" alt="" style={{width: '100%', float: 'right'}} /></span>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div> 
              </div> 
            </div> 
          </div> 
        </div>
        <section id="tl-service" className="tl-service bg-solid">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 text-center">
                <div className="section-header wow fadeIn">
                  <h2 className="section-title">Our services</h2>
                </div>
              </div> 
            </div>
            <div className="row">
              <div className="col-lg-4 wow zoomIn" data-wow-delay=".1s">
                <div className="single-service-box">
                  <div className="service-header color-green">
                    <h3 className="service-title">OSSD - Obtaining Ontario Secondary School Diploma (OSSD) </h3>
                  </div> 
                  <p className="service-desc">This diploma is acceptable in several countries.<br /><br />
                    Your children can study from the comfort of their home or travel all the way to Canada for in
                    class lessons. This is available for students in Grade 9 - 12. Grade 10 – 12 is
                    equivalent of SS1 - 3. </p>
                </div>
              </div> 
              <div className="col-lg-4 wow zoomIn" data-wow-delay=".3s">
                <div className="single-service-box">
                  <div className="service-header color-yellow">
                    <h3 className="service-title">University Prep/ College Pathway</h3>
                  </div> 
                  <p className="service-desc"><br /><br />For children looking to gain admission
                    into Canadian universities, we can assist with our partner institutions in university prep focusing on areas of interest such as Science and Technology, Business, Humanities and so much more.</p>
                </div> 
              </div> 
              <div className="col-lg-4 wow zoomIn" data-wow-delay=".5s">
                <div className="single-service-box">
                  <div className="service-header color-orange">
                    <h3 className="service-title">Admission processing </h3>
                  </div>
                  <p className="service-desc"><br />We render Admission processing services into top Canadian Colleges and Universities for
                    intending students who wish to gain admission.<br />
                    <br /> <br />We work with several institutions to assist with gaining admission into several Canadian schools. </p>
                </div>
              </div>
            </div>
            <div className="gap-30" />
          </div> 
        </section>
        <section id="tl-portfolio" className="tl-portfolio">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 text-center">
                <div className="section-header wow fadeIn">
                  <h2 className="section-title">Our Partners</h2>
                </div> 
              </div> 
            </div> 
            <div className="row">
              <div className="col-lg-12">
                <img className src="assets/images/logos/partner.png" style={{width: '20%'}} alt="" />
              </div>
            </div>
          </div>
        </section>
        <footer id="tl-footer" className="tl-footer">
          <div id="tl-footer-info" className="tl-footer-info">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <span>At Almak Consulting we pay attention to your needs, and work
                    with you to achieve them.</span>
                  <br />
                  <br />
                  <h3 className="footer-title">Get in Touch!<br />
                    <span>We are here to help</span>
                  </h3> 
                </div> 
                <div className="col-lg-4">
                  <div className="footer-contact-info color-yellow">
                    <i className="icon icon-location-pin" />
                    <h3>We are available</h3>
                    <p>8:00 AM - 4:00 PM</p>
                  </div>
                </div> 
                <div className="col-lg-4">
                  <div className="footer-contact-info color-green">
                    <i className="icon icon-phone" />
                    <h3>Contact Us Today</h3>
                    <p>  +(416) 206 2322</p>
                  </div> 
                </div> 
              </div>
              <br />
              <div className="row">
                <div className="col-lg-3">
                  <p className="copyright-text">© 2021 Almak Consulting.</p>
                </div> 
                <div className="col-lg-3 ml-lg-auto text-lg-right">
                </div> 
              </div> 
              <br />
            </div>
          </div>
          <div className="back-to-top" id="back-to-top" data-offset-top={10}>
            <button className="btn btn-primary" title="Back to Top">
              <i className="fa fa-angle-double-up" />
            </button>
          </div>
        </footer>
      </div>
          
      );
    
  };

export default Home;
