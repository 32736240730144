import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './component/website/home';
import About from './component/website/about';
import Contact from './component/website/contact';


export default function BasicExample() {
  return (
    <Router>
      <div>
       

        <Switch>
         
          <Route exact path="/" component={Home}/>
           <Route path="/about" component={About}/>
           <Route path="/contact" component={Contact}/>
          
         
            
          
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.



