import React, { useState } from 'react';

function About(){
      return (
        <div>
        <div id="tl-site-top" className="tl-site-top">
          <header id="tl-header" className="tl-header">
            <div className="tl-logo-area">
              <div className="container">
                <div className="row align-items-lg-center">
                  <div className="col-lg-2">
                    <div className="tl-logo">
                      <a href="/"><img src="assets/images/logos/logo-gg.jpg" style={{width: '100%'}} className="mg-fluid" /></a>
                    </div>
                  </div> 
                  <div className="col-lg-8">
                    <nav className="navbar navbar-expand-lg">
                      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                          <i className="icon icon-menu" />
                        </span>
                      </button>
                      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav ">
                          <li className="nav-item dropdown ">
                            <a className="nav-link" href="/">
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" href="about">
                              About us
                            </a>
                          </li>
                          <li className="nav-item ">
                            <a className="nav-link" href="contact">
                              Contact Us
                            </a>
                          </li>
                        </ul>
                      </div> 
                    </nav> 
                  </div> 
                </div> 
              </div>
            </div>
          </header>
        </div>
        <section id="tl-video-area" className="tl-video-area video-bg bg-overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="video-area-wrapper">
                  <a href="https://res.cloudinary.com/ayosalako/video/upload/v1623292213/almak.mp4" className="video-icon video-play-btn">
                    <i className="fa fa-play" />
                  </a>
                  <h5 className="video-title">Who we are</h5>
                  <div className="video-btn-group">
                    <a href="contact" className="btn btn-success btn-lg">Contact us now</a>
                  </div>
                </div> 
              </div>
            </div> 
          </div> 
        </section>
        <section id="main-container" className="main-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <h2 className="column-title">
                  Almak Consulting
                </h2>
                <div className="about-content">
                  <p>Almak Consulting is a reputable Canadian firm with partnership agreement with several
                    Canadian schools, colleges, and institutions. Our aim is to assist you in furthering your studies
                    in Canada.</p>
                  <p>We are excited whenever any of our client’s gain admission into Canadian schools through any
                    of our partners because your satisfaction is our motivation.</p>
                </div> 
              </div> 
              <div className="col-lg-4">
                <div className="about-box about-bg">
                  <img src="assets/images/characters.png" style={{width: '150%'}} />
                </div> 
              </div>
            </div> 
          </div> 
        </section>
        <footer id="tl-footer" className="tl-footer">
          <div id="tl-footer-info" className="tl-footer-info">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <span>At Almak Consulting we pay attention to your needs, and work
                    with you to achieve them.</span>
                  <br />
                  <br />
                  <h3 className="footer-title">Get in Touch!<br />
                    <span>We are here to help</span>
                  </h3> 
                </div> 
                <div className="col-lg-4">
                  <div className="footer-contact-info color-yellow">
                    <i className="icon icon-location-pin" />
                    <h3>We are available</h3>
                    <p>8:00 AM - 4:00 PM</p>
                  </div>
                </div> 
                <div className="col-lg-4">
                  <div className="footer-contact-info color-green">
                    <i className="icon icon-phone" />
                    <h3>Contact Us Today</h3>
                    <p>  +(416) 206 2322</p>
                  </div> 
                </div> 
              </div>
              <br />
              <div className="row">
                <div className="col-lg-3">
                  <p className="copyright-text">© 2021 Almak Consulting.</p>
                </div> 
                <div className="col-lg-3 ml-lg-auto text-lg-right">
                </div> 
              </div> 
              <br />
            </div>
          </div>
          <div className="back-to-top" id="back-to-top" data-offset-top={10}>
            <button className="btn btn-primary" title="Back to Top">
              <i className="fa fa-angle-double-up" />
            </button>
          </div>
        </footer>
      </div>
          
      );
    
  };

export default About;
